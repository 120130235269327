import React, { useEffect } from 'react';
import * as bxlPOS from '../BixolonJS/bxlpos.js';
import { amtStr } from 'helpers/numFormatter';
import BigNumber from 'bignumber.js';
import {
	OrderDiscountStatus,
	PaymentClass,
	useGetTaxSchemeDetailLazyQuery,
	useGetTaxSummaryQuery,
} from 'generated/graphql';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { alignment } from './BillReceiptFunctions';
import { SchemeType } from 'containers/OutletModule/CommonSettings/TaxSetup/TaxSetupListing';
const dateFormat = require('dateformat');

export const BillReceiptFooter = (
	total,
	discount,
	discountCode,
	subtotal,
	serviceCharge,
	tax,
	mode,
	query,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	currencyCode,
	rounding,
	taxSchemeInfo,
	getTaxSchemeDetail,
	officer,
	staff,
	getTaxSummary,
) => {
	// function
	const handleCardType = cardType => {
		switch (cardType) {
			case 'VISA_CARD':
				return 'VISA';
			case 'MASTERCARD':
				return 'MASTERCARD';
			case 'AMERICAN_EXPRESS_CARD':
				return 'AMERICAN EXPRESS';
			case 'DINERS_CARD':
				return 'DINERS';
			case 'JCB_CARD':
				return 'JCB';
			case 'UNIONPAY_CARD':
				return 'UNIONPAY';
			case 'DEBIT_CARD':
				return 'DEBIT';
			default:
				return 'Credit';
		}
	};

	// rounding amount
	let roundAmount = 0;
	latestPayment?.map(x => {
		roundAmount += x?.roundingAmount;
	});

	const company = query?.outlet?.company;
	const qrCodeUrl = query?.qrCodeUrl !== null ? query?.qrCodeUrl : null;

	// console?.log(company.qrCodeUrl, 'gg');

	const eInvoiceQr = () => {
		const condition =
			query?.is_einvoice === true &&
			company?.isEInvIntegrated === true &&
			dateFormat(query?.createdTs, 'yyyy-mm-dd') >=
				dateFormat(company?.eInvStartDate, 'yyyy-mm-dd') &&
			dateFormat(query?.createdTs, 'yyyy-mm-dd') <=
				dateFormat(company?.eInvEndDate, 'yyyy-mm-dd') &&
			qrCodeUrl !== null;
		console?.log(condition, qrCodeUrl, 'condition');

		const result =
			condition === true
				? (bxlPOS.printBitmap(qrCodeUrl, 200, 1, false),
				  bxlPOS.printText(
						`Scan within 72 hours if\n`,
						0,
						0,
						false,
						false,
						false,
						0,
						1,
				  ),
				  bxlPOS.printText(
						`e-Invoice is required\n`,
						0,
						0,
						false,
						false,
						false,
						0,
						1,
				  ))
				: null;
		return result;
	};

	// sub total
	alignment(` `, `\n`, false);
	alignment(`Sub-Total`, `${currencyCode + amtStr(subtotal)}\n`, false);

	// discount
	const activeOrderDiscount =
		mode === 'bill-settlement'
			? query?.orderDiscount?.filter(
					y => y?.status === OrderDiscountStatus?.Active,
			  )[0]
			: query?.order?.orderDiscount?.filter(
					y => y?.status === OrderDiscountStatus?.Active,
			  )[0];

	alignment(
		`Discount: ${discountCode?.filter(
			x => x?.ID === activeOrderDiscount?.discountID,
		)[0]?.code ?? ' '}`,
		`(${currencyCode}${amtStr(discount)})\n`,
		false,
	);

	// rounding
	if (mode === 'bill-settlement') {
		if (rounding < 0) {
			alignment(
				`Rounding`,
				`(${currencyCode}${Math.abs(rounding).toFixed(2)})\n`,
				false,
			);
		} else {
			alignment(`Rounding`, `${currencyCode}${amtStr(rounding)}\n`, false);
		}
	} else {
		if (roundAmount < 0) {
			alignment(
				`Rounding`,
				`(${currencyCode}${Math.abs(roundAmount).toFixed(2)})\n`,
				false,
			);
		} else {
			alignment(`Rounding`, `${currencyCode}${amtStr(roundAmount)}\n`, false);
		}
	}

	// total
	alignment(` `, `\n`, true);
	if (mode === 'bill-settlement') {
		alignment(
			`Total`,
			`${currencyCode}${amtStr(new BigNumber(Math.abs(total)).toNumber())}\n`,
			true,
		);
	} else {
		alignment(
			`Total`,
			`${currencyCode}${amtStr(
				new BigNumber(Math.abs(total)).plus(roundAmount ?? 0).toNumber(),
			)}\n`,
			true,
		);
	}
	alignment(` `, `\n`, true);

	const sortedTaxScheme = getTaxSchemeDetail?.sort((a, b) => {
		if (a.taxType < b.taxType) return -1;
		if (a.taxType > b.taxType) return 1;

		if (a.taxRate < b.taxRate) return -1;
		if (a.taxRate > b.taxRate) return 1;

		return 0;
	});

	const taxSchemeFooterInfo =
		mode === 'bill-settlement'
			? query?.taxSchemeFooterInfo
			: query?.order?.taxSchemeFooterInfo;

	const taxAmount = (taxType, taxRate) => {
		const tax = taxSchemeFooterInfo
			?.filter(x => x?.name === taxType)
			?.filter(v => parseInt(v?.rate) === taxRate)[0];

		return tax?.sum;
	};

	// tax
	if (sortedTaxScheme?.length > 0) {
		sortedTaxScheme?.map(x => {
			alignment(
				`${x?.taxCode} (${x?.taxRate}%)`,
				`${currencyCode}${amtStr(Number(taxAmount(x?.taxType, x?.taxRate)))}\n`,
				false,
			);
		});
	}

	// bill settlement
	if (mode === 'bill-settlement') {
		alignment(` `, `\n`, true);
		bxlPOS.printText(
			`Name : ___________________________________\n\n`,
			0,
			0,
			false,
			false,
			false,
			0,
			0,
		);
		bxlPOS.printText(
			`Room/ACC : _______________________________\n\n`,
			0,
			0,
			false,
			false,
			false,
			0,
			0,
		);
		bxlPOS.printText(
			`Signature : ______________________________\n\n`,
			0,
			0,
			false,
			false,
			false,
			0,
			0,
		);
	}

	if (mode === 'void-bill' || mode === 'bill-settlement') {
	} else {
		alignment(` `, `\n`, true);
		alignment(`Payment`, `\n`, true);

		latestPayment?.map(el => {
			if (el?.paymentClass === PaymentClass.UnsettledBill) {
				alignment(
					`Unsettled Bill`,
					`${currencyCode}${amtStr(el?.amount)}\n`,
					false,
				);
			} else if (el?.paymentClass === PaymentClass.Credit) {
				alignment(
					`${handleCardType(el?.creditCardType)}`,
					`${currencyCode}${amtStr(el?.receivedAmount)}\n`,
					false,
				);
			} else if (el?.paymentClass === PaymentClass.Online) {
				alignment(
					`Online Payment`,
					`${currencyCode}${amtStr(el?.receivedAmount)}\n`,
					false,
				);
			} else if (el?.paymentClass === PaymentClass.NonGuest) {
				alignment(
					`Non Guest`,
					`${currencyCode}${amtStr(el?.receivedAmount)}\n`,
					false,
				);
			} else if (el?.paymentClass === PaymentClass.SuspendFolio) {
				alignment(
					`Suspend Folio`,
					`${currencyCode}${amtStr(el?.receivedAmount)}\n`,
					false,
				);
			} else if (el?.paymentClass === PaymentClass.BankTt) {
				alignment(
					`Bank TT`,
					`${currencyCode}${amtStr(el?.receivedAmount)}\n`,
					false,
				);
			} else {
				alignment(
					`${toProperCase(el?.paymentClass)}`,
					`${currencyCode}${amtStr(el?.receivedAmount)}\n`,
					false,
				);
			}

			if (el?.paymentClass === PaymentClass.Room) {
				alignment(
					`  Charged to`,
					`${el?.hotelGuestInfo?.RoomNo} | ${el?.hotelGuestInfo?.FullName}  \n`,
					false,
				);
			}

			if (
				el?.paymentClass === PaymentClass.NonGuest ||
				el?.paymentClass === PaymentClass.SuspendFolio
			) {
				alignment(`  Charged to:`, `\n`, true);
				alignment(`  ${el?.hotelGuestInfo?.FullName}`, `\n`, false);
			}

			if (el?.paymentClass === PaymentClass.Debtor) {
				alignment(
					`  Charged to`,
					`${el?.hotelGuestInfo?.GuestType} | ${el?.hotelGuestInfo?.FullName}  \n`,
					false,
				);
			}

			if (el?.paymentClass === PaymentClass.Staff) {
				alignment(
					`  Charged to`,
					`${staff[0]?.designation} | ${staff[0]?.name}  \n`,
					false,
				);
			}

			if (el?.paymentClass === PaymentClass.Officer) {
				alignment(
					`  Charged to`,
					`${officer[0]?.designation} | ${officer[0]?.name}  \n`,
					false,
				);
			}

			if (
				el?.paymentClass === PaymentClass.BankTt ||
				el?.paymentClass === PaymentClass.Cheque ||
				el?.paymentClass === PaymentClass.Online ||
				el?.paymentClass === PaymentClass.Skipper ||
				el?.paymentClass === PaymentClass.Member
			) {
				alignment(`  Remark: `, `${el?.remark ?? '-'}  \n`, false);
			}

			if (el?.paymentClass === PaymentClass.Voucher) {
				alignment(`  Code: `, `${el?.referenceNo ?? '-'}  \n`, false);
			}

			if (el?.paymentClass === PaymentClass.Others) {
				alignment(`  Remarks: `, `${el?.referenceNo ?? '-'}  \n`, false);
			}

			if (el?.paymentClass === PaymentClass.Credit) {
				alignment(
					`  Card ref: `,
					`${`****${el?.cardNo?.slice(-4)}` ?? '-'}  \n`,
					false,
				);
				if (el?.hotelGuestInfo?.CompanyName !== undefined) {
					alignment(
						`  Charged to: `,
						`${el?.hotelGuestInfo?.DebtorAccount}${' | '}${
							el?.hotelGuestInfo?.CompanyName
						}`,
						false,
					);
				}
			}

			if (el?.paymentClass === PaymentClass.Cash && el?.changeAmount > 0) {
				alignment(
					`  Change: `,
					`${currencyCode}${amtStr(el?.changeAmount)}\n`,
					false,
				);
			}
		});

		if (latestPayment?.length === 0) {
			const OCENT = discountCode?.filter(
				x => x?.ID === activeOrderDiscount?.discountID,
			)[0]?.discountType;

			const displayOCENT =
				OCENT === 'OFFICER_CHECK'
					? 'Officer Check'
					: OCENT === 'ENTERTAINMENT'
					? 'Entertainment'
					: OCENT === 'DISCOUNT'
					? 'Discount'
					: 'Promo';

			alignment(
				`${displayOCENT}`,
				`${currencyCode}${amtStr(latestPayment?.amount)}\n`,
				false,
			);
		}

		const totalAmount = getTaxSummary?.reduce((a, b) => {
			return a + b?.amount;
		}, 0);

		const totalTaxAmount = getTaxSummary?.reduce((a, b) => {
			return a + b?.taxAmount;
		}, 0);

		alignment(` `, `\n`, false);
		alignment(`SST Summary`, `\n`, true);

		alignment(`Tax Cd`, `Amt Excl SST(RM) Tax(RM)\n`, true);

		if (getTaxSummary?.length > 0) {
			getTaxSummary?.map(x => {
				alignment(
					`${x?.code}`,
					`${amtStr(x?.amount)}       ${amtStr(x?.taxAmount)}\n`,
					false,
				);
			});
		}

		alignment(
			`Total`,
			`${amtStr(totalAmount)}       ${amtStr(totalTaxAmount)}\n`,
			true,
		);

		eInvoiceQr();

		alignment(` `, `\n`, false);
		bxlPOS.printText(
			// `Thank you. Please come again!\n`,
			`Thank you & we hope to see you again soon.\n`,
			0,
			0,
			false,
			false,
			false,
			1,
			1,
		);
	}
};
