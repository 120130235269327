import { useMediaQuery } from '@material-ui/core';
import {
	createStyles,
	makeStyles,
	Theme,
	useTheme,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import AppContext from 'containers/App/Store/AppContext';
import React, { cloneElement, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { LayoutDrawer } from './Drawer';
import Header from './Header';

const drawerWidth = 240;

export interface LayoutProps {
	children: any;
}

export const Layout = (props: LayoutProps) => {
	const { children } = props;
	const theme = useTheme();
	const history = useHistory();
	const { globalState, dispatch }: any = useContext(AppContext as any);

	let settings = useRouteMatch({
		path: [
			'/authentication/404',
			`/login`,
			`/kitchen/login`,
			`/printer-server/login`,
			`/logout`,
			`/reset-password/:token`,
			`/forgot-password`,
			`/user/activate/:token`,
			`/user/create-password/:token`,
			'/forgot',
			'/signOut',
			'/user/activate/:token',
			'/menux/outlet/:outletID/bill-qr/:billID',
			'/qrinvoice/3rd-party',
			'/qr-invoice',
		],
		strict: true,
		sensitive: true,
	});

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				display: 'flex',
			},
			drawer: {
				[theme.breakpoints.up('sm')]: {
					width: drawerWidth,
					flexShrink: 0,
					top: 64,
				},
			},
			appBar: {
				[theme.breakpoints.up('sm')]: {
					width: `calc(100% - ${drawerWidth}px)`,
					marginLeft: drawerWidth,
				},
			},
			menuButton: {
				marginRight: theme.spacing(2),
				[theme.breakpoints.up('sm')]: {
					display: 'none',
				},
			},
			// necessary for content to be below app bar
			drawerPaper: {
				width: drawerWidth,
				[theme.breakpoints.up('sm')]: {
					top: 64,
					height: 'calc(100% - 64px)',
				},
				zIndex: 100,
			},
			content: {
				flexGrow: 1,
				padding: theme.spacing(3),
				overflow: 'auto',
			},
			mainContent: {
				position: 'relative',
				top: !settings && 70,
				flexGrow: 1,
				// overflow: !settings && 'auto',
				overflow: !settings && 'hidden',
				transition: theme.transitions.create('margin', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				[theme.breakpoints.up('sm')]: {
					top: !settings && 70,
					marginLeft: -drawerWidth,
					padding: theme.spacing(3),
				},
			},

			contentShift: {
				transition: theme.transitions.create('margin', {
					easing: theme.transitions.easing.easeOut,
					duration: theme.transitions.duration.enteringScreen,
				}),
				marginLeft: 0,
			},
			toolbar: theme.mixins.toolbar,
		}),
	);
	const classes = useStyles();

	// Desktop Checking
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	return (
		<>
			<div className={classes.root}>
				{!settings &&
					history?.location?.pathname !== '/' &&
					(isDesktop ? <Header onDrawerOpen={handleDrawer} /> : null)}
				{!settings && history?.location?.pathname !== '/' && (
					<LayoutDrawer
						onCloseDrawer={isDesktop ? !handleDrawer : handleDrawer}
						open={isDesktop ? globalState.drawerOpen : !globalState.drawerOpen}
						variant={isDesktop ? 'persistent' : 'temporary'}
					/>
				)}
				<main
					style={{
						marginLeft:
							isDesktop &&
							!settings &&
							history?.location?.pathname !== '/' &&
							globalState.drawerOpen
								? drawerWidth
								: 0,
					}}
					className={clsx(classes.mainContent, {
						[classes.contentShift]: globalState.drawerOpen,
					})}
				>
					{cloneElement(children, {})}
				</main>
			</div>
		</>
	);
};
export default Layout;
